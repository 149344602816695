import axios from "axios";
import { PAGE_SIZE, STATUS, BASE_URL } from "@/utils/constants";
import BaseEntityModel from "./../general/BaseEntityModel";
import SponsorsFilter from "./SponsorsFilter";
import i18n from "@/i18n";
import store from "@/store";
import sponsorsImg from "@/assets/images/sponsors.svg";

export default class Sponsor extends BaseEntityModel {
  constructor() {
    super();
    this.setInitialValue();
  }
  setInitialValue() {
    this.sponsorToken = "";
    this.dailyCode = "";
    this.fullCode = "";
    this.sponsorNameCurrent = "";
    this.sponsorNameEn = "";
    this.sponsorNameAr = "";
    this.sponsorDescriptionCurrent = "";
    this.sponsorDescriptionAr = "";
    this.sponsorDescriptionEn = "";
    this.sponsorImagePath = "";
    this.sponsorNotes = "";
    this.sponsorPreferedTypeToken = "";
    this.sponsorPreferedTypeNameCurrent = "";
    this.sponsorPreferedTypeNameEn = "";
    this.sponsorPreferedTypeNameAr = "";
    this.sponsorPreferedTypeNameUnd = "";
    this.sponsorArchiveStatus = "";
    this.mediaFile = "";
  }
  fillData(data) {
    if (data) {
      this.fillDataBaseEntityModelBaseEntityModel(data);
      this.sponsorToken = data.sponsorToken;
      this.dailyCode = data.dailyCode || "";
      this.fullCode = data.fullCode || "";
      this.sponsorNameCurrent = data.sponsorNameCurrent;
      this.sponsorNameEn = data.sponsorNameEn;
      this.sponsorNameAr = data.sponsorNameAr;
      this.sponsorDescriptionCurrent = data.sponsorDescriptionCurrent;
      this.sponsorDescriptionAr = data.sponsorDescriptionAr;
      this.sponsorDescriptionEn = data.sponsorDescriptionEn;
      this.sponsorImagePath = data.sponsorImagePath;
      this.sponsorNotes = data.sponsorNotes;
      this.sponsorPreferedTypeToken = data.sponsorPreferedTypeToken;
      this.sponsorPreferedTypeNameCurrent = data.sponsorPreferedTypeNameCurrent;
      this.sponsorPreferedTypeNameEn = data.sponsorPreferedTypeNameEn;
      this.sponsorPreferedTypeNameAr = data.sponsorPreferedTypeNameAr;
      this.sponsorPreferedTypeNameUnd = data.sponsorPreferedTypeNameUnd;
      this.sponsorArchiveStatus = data.sponsorArchiveStatus;
    }
  }

  async getAllSponsors(language, userAuthorizeToken, pagination, filterData) {
    return await axios.get(
      `/api/Sponsors/GetAllSponsors?language=${language}&userAuthorizeToken=${userAuthorizeToken}&timeZoneToken=&page=${pagination.selfPage}&pageSize=${PAGE_SIZE}&filterStatus=true&paginationStatus=true&establishmentToken=${filterData.establishmentToken}&creationDateTime=${filterData.creationDateTime}&lastUpdateDateTime=${filterData.lastUpdateDateTime}&createdBysponsorToken=${filterData.createdByUserToken}&lastUpdatedByUserToken=${filterData.lastUpdatedByUserToken}&textSearch=${filterData.textSearch}`
    );
  }

  async getDialogOfSponsors(
    language,
    userAuthorizeToken,
    filterData = new SponsorsFilter()
  ) {
    let options = [];
    try {
      let response = await axios.get(
        `/api/Sponsors/GetSponsorDialog?language=${language}&userAuthorizeToken=${userAuthorizeToken}&filterStatus=true&textSearch=${filterData.textSearch}`
      );
      const itemsData = response.data.itemsData;
      if (response.data.status == STATUS.SUCCESS) {
        for (let item in itemsData) {
          options.push({
            value: itemsData[item]["itemToken"],
            text: itemsData[item]["itemName"],
            image: itemsData[item]["itemImagePath"]
              ? BASE_URL + itemsData[item]["itemImagePath"]
              : sponsorsImg,
          });
        }
      } else if (response.data.status == STATUS.INVALID_TOKEN) {
        store.dispatch("logoutUser");
      } else {
        options.push({
          value: "",
          text: response.data.msg,
          image: sponsorsImg,
        });
      }
    } catch (error) {
      options.push({
        value: "",
        text: i18n.t("errorCatch"),
        image: sponsorsImg,
      });
    }
    return options;
  }

  async getSponsorDetails(language, userAuthorizeToken) {
    return await axios.get(
      `/api/Sponsors/GetSponsorDetails?language=${language}&userAuthorizeToken=${userAuthorizeToken}&token=${this.sponsorToken}`
    );
  }

  async addOrUpdateSponsor(language, userAuthorizeToken) {
    var formData = new FormData();
    formData.append("mediaFile", this.mediaFile);
    formData.append("language", language);
    formData.append("userAuthorizeToken", userAuthorizeToken);
    formData.append("sponsorToken", this.sponsorToken);
    formData.append("fullCode", this.fullCode);
    formData.append("sponsorNameAr", this.sponsorNameAr);
    formData.append("sponsorNameEn", this.sponsorNameEn);
    formData.append("sponsorDescriptionAr", this.sponsorDescriptionAr);
    formData.append("sponsorDescriptionEn", this.sponsorDescriptionEn);

    formData.append("sponsorPreferedTypeToken", this.sponsorPreferedTypeToken);
    formData.append("sponsorNotes", this.sponsorNotes);

    if (this.sponsorToken == "" || this.sponsorToken == undefined) {
      return await axios.post(`/api/Sponsors/AddSponsor`, formData);
    } else {
      return await axios.post(`/api/Sponsors/UpdateSponsor`, formData);
    }
  }

  async archiveSponsor(language, userAuthorizeToken) {
    var data = {
      language: language,
      userAuthorizeToken: userAuthorizeToken,
      token: this.sponsorToken,
      archiveStatus: true,
    };

    return await axios.post(`/api/Sponsors/ArchiveSponsor`, data);
  }
}
