<template>
  <div class="">
    <PreLoader v-if="isLoading" />
    <ExceptionWithImg v-if="exceptionMsg" :msg="exceptionMsg" />

    <form autocomplete="off">
      <div class="row">
        <div
          class="imgView"
          :style="{ 'background-image': `url(${imageSrc})` }"
          :onerror="`this.src='${defaultImg}'`"
          alt="Image"
          @click="selectImage"
        ></div>
        <!-- @input="pickFile" -->
        <input
          ref="fileInput"
          type="file"
          v-on:change="pickFile"
          class="d-none"
          accept=".jpg, .jpeg, .png"
        />
      </div>

      <div class="my-card">
        <div class="row">
          <CustomSelectBox
            :className="'col-md-12'"
            :id="'sponsorPreferedTypeToken'"
            :value="sponsor.sponsorPreferedTypeToken"
            :options="preferedTypeTokenOptions"
            v-on:changeValue="sponsor.sponsorPreferedTypeToken = $event"
            :title="$t('selectPreferedType')"
            :imgName="'favorite-add.svg'"
          />
          <CustomInput
            :className="'col-md-6'"
            :id="'sponsorNameAr'"
            :value="sponsor.sponsorNameAr"
            :title="$t('sponsors.nameAr')"
            :imgName="'sponsors.svg'"
            v-on:changeValue="sponsor.sponsorNameAr = $event"
          />
          <CustomInput
            :className="'col-md-6'"
            :id="'sponsorNameEn'"
            :value="sponsor.sponsorNameEn"
            :title="$t('sponsors.nameEn')"
            :imgName="'sponsors.svg'"
            v-on:changeValue="sponsor.sponsorNameEn = $event"
          />

          <TextArea
            :className="'col-md-6'"
            :id="'sponsorDescriptionAr'"
            :value="sponsor.sponsorDescriptionAr"
            v-on:changeValue="sponsor.sponsorDescriptionAr = $event"
            :title="$t('sponsors.descriptionAr')"
            :imgName="'description.svg'"
          />
          <TextArea
            :className="'col-md-6'"
            :id="'sponsorDescriptionEn'"
            :value="sponsor.sponsorDescriptionEn"
            v-on:changeValue="sponsor.sponsorDescriptionEn = $event"
            :title="$t('sponsors.descriptionEn')"
            :imgName="'description.svg'"
          />

          <TextArea
            :className="'col-md-6'"
            :id="'sponsorNotes'"
            :value="sponsor.sponsorNotes"
            v-on:changeValue="sponsor.sponsorNotes = $event"
            :title="$t('notes')"
            :imgName="'notes.svg'"
          />
          <CustomInput
            :className="'col-md-6'"
            :id="'fullCode'"
            :value="sponsor.fullCode"
            v-on:changeValue="sponsor.fullCode = $event"
            :title="$t('code')"
            :imgName="'number.svg'"
          />
        </div>
      </div>

      <div class="form-footer">
        <button
          name="submit"
          type="submit"
          class="btn btn-submit"
          @click.prevent="addOrUpdateSponsor"
        >
          {{ submitName }}
        </button>
        <router-link :to="{ name: 'Sponsors' }" class="btn btn-cancel">
          {{ $t("cancel") }}
        </router-link>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PreLoader from "@/components/general/PreLoader.vue";
import ExceptionWithImg from "@/components/general/ExceptionWithImg.vue";
import DEFAULT_IMG_USER from "@/assets/images/sponsors.svg";
import CustomInput from "@/components/general/CustomInput.vue";
import TextArea from "@/components/general/TextArea.vue";
import createToastMixin from "@/utils/create-toast-mixin";
import { setDataMultiLang } from "@/utils/functions";
import CustomSelectBox from "@/components/general/CustomSelectBox.vue";

export default {
  name: "SponsorForm",
  mixins: [createToastMixin],
  components: {
    PreLoader,
    ExceptionWithImg,
    CustomInput,
    TextArea,
    CustomSelectBox,
  },
  computed: {
    ...mapGetters(["userAuthorizeToken", "constantsListsData"]),
  },
  data() {
    return {
      isLoading: false,
      exceptionMsg: null,
      exceptionImg: null,
      language: localStorage.getItem("userLanguage") || "ar",
      defaultImg: DEFAULT_IMG_USER,
      preferedTypeTokenOptions: [],
    };
  },
  props: ["sponsor", "imageSrc", "submitName"],
  methods: {
    selectImage() {
      this.$refs.fileInput.click();
    },
    pickFile() {
      let input = this.$refs.fileInput;
      let file = input.files;
      if (file && file[0]) {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.imageSrc = e.target.result;
        };
        reader.readAsDataURL(file[0]);
        this.$emit("input", file[0]);
        this.sponsor.mediaFile = file[0];
      } else {
        this.imageSrc = DEFAULT_IMG_USER;
        this.sponsor.mediaFile = "";
      }
    },
    async getDialogOfPreferedType() {
      this.isLoading = true;
      this.preferedTypeTokenOptions = [];
      this.preferedTypeTokenOptions.push({
        value: "",
        text: this.$t("selectPreferedType"),
      });
      let preferedTypes = this.constantsListsData.listPreferedType;
      for (let item in preferedTypes) {
        this.preferedTypeTokenOptions.push({
          value: preferedTypes[item]["itemToken"],
          text: setDataMultiLang(
            this.language,
            preferedTypes[item]["itemNameAr"],
            preferedTypes[item]["itemNameEn"]
          ),
        });
      }
      this.isLoading = false;
    },
    async addOrUpdateSponsor() {
      this.$emit("addOrUpdateSponsor");
    },
  },
  watch: {},
  async created() {
    this.getDialogOfPreferedType();
  },
};
</script>

<style lang="scss"></style>
